<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="5" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="7" v-if="$Can('contact_add_btn')">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" class="d-inline-block mr-1" cle placeholder="Search..." />
                <b-button variant="primary" router-link to="/contact/contact-add">
                  <span class="text-nowrap">Add Contact</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="listCustomers"
          @sort-changed="sortingChanged"
          :no-local-sorting="true"
          selectable
          select-mode="single"
          responsive
          :small="true"
          :fields="customerHeaders"
          show-empty
          empty-text="No matching records found"
          @row-selected="onRowSelected"
          :per-page="perPage"
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <strong class="text-primary" v-if="data.item.archive == 'active'">#{{ Number(data.item.id ? data.item.id : 0) }}</strong>
            <strong class="text-dark" v-else
              ><s>#{{ Number(data.item.id ? data.item.id : 0) }}</s></strong
            >
          </template>
          <!-- Column: firstName -->
          <template #cell(firstName)="data">
            {{ data.item.firstName ? data.item.firstName : 'N/A' }}
          </template>
          <!-- Column: lastName -->
          <template #cell(lastName)="data"> {{ data.item.lastName ? data.item.lastName : 'N/A' }}</template>
          <!-- Column: phoneNumber -->
          <template #cell(phoneNumber)="data">
            {{ data.item.phoneNumber ? data.value : 'N/A' }}
          </template>
          <!-- Column: email -->
          <template #cell(email)="data">
            {{ data.item.email ? data.item.email : 'N/A' }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data" >
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" v-if="$Can('contact_edit_btn')">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item :to="{ name: 'contact-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit Contact</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.archive == 'active'" @click="sendtToArchive(data.item.id)">
                <feather-icon icon="SendIcon" />
                <span class="align-middle ml-50">Send To Archive</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <a class="ml-2 mb-2 mr-2 float-right text-primary" @click="toggleRecordView">
          {{ $route.name === 'contact-list' ? 'Show archived records' : 'Show active records' }}
        </a>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import customerStoreModule from '../customerStoreModule';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
  components: {
    vSelect,
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
  },

  setup() {
    const CUSTOMER_APP_STORE_MODULE_NAME = 'customer';

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, customerStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    return {
      filter: null,
      perPage: localStorage.getItem('customerShow') ?? 10,
      fetchCustomers: [],
      listCustomers: [],
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      colWidthFactor: null,
      totalRows: 0,
      excelJson: {},
      baseURL: store.state.app.baseURL,
      loading: false,
      isArchive: 'active',

      customerHeaders: [
        {
          key: 'id',
          label: 'ID',
          class: 'text-center',
          thStyle: 'width: 15%',
        },
        {
          key: 'firstName',
          label: 'FIRST NAME',
          class: 'text-center',
          thStyle: 'width: 20%',
        },
        {
          key: 'lastName',
          label: 'LAST NAME',
          class: 'text-center',
          thStyle: 'width: 20%',
        },
        {
          key: 'phoneNumber',
          label: 'PHONE NUMBER',
          class: 'text-center',
          thStyle: 'width: 15%',
        },
        {
          key: 'email',
          label: 'EMAIL',
          class: 'text-center',
          thStyle: 'width: 25%',
        },
        {
          key: 'actions',
          label: '#',
          class: 'text-center',
          thStyle: 'width: 5%',
        },
      ],

      status: null,
      sortBy: 'id',
      sortDesc: true,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('customerShow', val);

        this.getItems();
      },
    },

    currentPage: {
      handler: function(val, before) {
        this.getItems();
      },
    },

    filter: _.debounce(function(newVal) {
      this.getItems();
    }, 500),

    sortDesc: {
      handler: function(val, before) {
        if (this.sortBy != '') {
          this.getItems();
        }
      },
    },
  },

  methods: {
    sendtToArchive(id) {
      this.loading = true;

      store
        .dispatch('customer/contactSendToArchive', id)
        .then((res) => {
          this.loading = false;
          this.getItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Contact has been sent to archive',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    toggleRecordView() {
      if (this.$route.name === 'contact-list') {
        this.isArchive = 'archived';
        this.getItems();
        this.$router.push({ name: 'contact-archive-list' });
      } else {
        this.isArchive = 'active';
        this.getItems();
        this.$router.push({ name: 'contact-list' });
      }
    },

    onRowSelected(item) {
      if(this.$Can('contact_edit_btn')){
        router.push({ name: 'contact-edit', params: { id: item[0].id } });

      }
    },

    sortingChanged(val) {
      this.sortBy = val.sortBy;
      this.sortDesc = val.sortDesc;
    },

    getItems() {
      this.loading = true;
      store
        .dispatch('customer/fetchCustomers', {
          perPage: this.perPage,

          page: this.currentPage,
          filter: this.filter,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          isArchive: this.isArchive,
        })
        .then((res) => {
          this.fetchCustomers = res.data.data.data;
          this.listCustomers = res.data.data.data;
          this.totalRows = res.data.data.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
